<template>
  <div class="tag-box flex">
     <span class="tag-lable">AR：</span>
     <a-row :gutter="20" v-if="arList.length > 0">
      <a-col class="mb-10" :span="4" v-for="item in arList" :key="item.id">
        <div class="img-box">
          <img :src="OSSURL + item.icon_path" class="img"/>
          <div class="playBox">
            <i class="iconfont icon-shipinbofang iconPlay" @click="handlePlay(item)"></i>
          </div>
        </div>
        <p class="mt-5 mb-0">{{ item.name }}</p>
      </a-col>
     </a-row>
     <noneData v-else></noneData>
  </div>
  <div class="txt-r mr-10" v-if="arList.length > 0">
    <a-pagination
      hideOnSinglePage
      v-model:current="current"
      v-model:pageSize="pageSize"
      :total="total"
      @change="changePage"
    />
  </div>

  <!-- 视频预览 start -->
  <videoDialog
    v-if="isShowPlayVideo"
    v-model:visible="isShowPlayVideo"
    :href="playUrl"
  ></videoDialog>
  <!-- 视频预览 end -->
</template>
<script>
import { defineComponent, onMounted, reactive, ref, watch, computed, toRefs } from "@vue/runtime-core"
import { fetchMoreWiki } from '@/api/subject/index.js'
import noneData from '@/common/components/none-data.vue'
import { OSSURL } from '@/common/hooks/oss-config'
import videoDialog from '@/components/videoDialog.vue'
export default defineComponent({
  props: ['subject_id', 'sourse'],
  components: {
    noneData,
    videoDialog
  },
  setup(props) {
    const searchName = ref('')
    const subject_id = computed(() => props.subject_id)
    const pagination = reactive({
      total: 0,
      current: 1,
      pageSize: 18
    })
    const selectedTags = ref([133]) // 已选标签
    const arList = ref([])

    const isShowPlayVideo = ref(false)
    const playUrl = ref('')
    const handlePlay = item => {
      isShowPlayVideo.value = true
      let _url = OSSURL + item.video_intro_path
      playUrl.value = _url
    }
    
    const getList = (searchValue, selectedTag, subjectId, page) => {
      if (page == 1) {
        pagination.current = 1
      }
      searchName.value = searchValue ? searchValue : searchName.value
      selectedTags.value = selectedTag ? selectedTag : selectedTags.value
      subject_id.value = subjectId ? subjectId : subject_id.value
      fetchMoreWiki({
        search: searchName.value,
        tag_ids: selectedTags.value.toString(),
        subject_id: subject_id.value,
        page: pagination.current,
        pagesize: pagination.pageSize
      }).then(res => {
        arList.value = res.data
        pagination.total = res.result
      })
    }
    const changePage = (page) => {
       pagination.current = page
       getList()
    }
     // 搜索
    const handelSearch = (searchValue) => {
      pagination.current = 1
      searchName.value = searchValue
      getList()
    }
    return {
      OSSURL,
      selectedTags,
      arList,
      handlePlay,
      isShowPlayVideo,
      playUrl,
      ...toRefs(pagination),
      changePage,
      handelSearch,
      getList
    }
  },
})
</script>
<style lang="scss" scoped>
@import '@/styles/course-ware.scss';
.img-box {
  position: relative;
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  & + p {
    color: #363535;
    line-height: 20px;
  }
  .playBox {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    width: 100%;
    height: 0;
    background: rgb(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    .iconPlay {
      color: #fff;
      font-size: 35px;
    }
  }
  &:hover .playBox {
    height: 100%;
    transition: all 0.5s;
  }
  .img{
    flex: 1;
  }
}
</style>