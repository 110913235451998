<template>
    <div class="none">
        <div class="tips-box">
            <img :src="imgUrl" alt="">
            <div class="tips">{{tips}}</div>
            <slot></slot>
        </div>
    </div>
</template>
<script>
import { defineComponent } from 'vue'
export default defineComponent({
    props: {
        imgUrl: {
            type: String,
            default: require('@/assets/icon_emptystate_01.png')
        },
        tips: {
            type: String,
            default: '暂无数据'
        }
    }
})
</script>
<style lang="scss" scoped>
.none {
    position: absolute;
    top: 50%;
    left: 0;
    right:0;
    transform: translate(0, -50%);
}
.tips-box{
    width:auto;
    text-align: center;
}
.tips{ 
    text-align: center;
    font-size: 14px;
    color: #6980A1;
    margin-top:30px;
}
.addActivity {
  text-align: center;
  font-size: 14px;
  line-height: 30px;
  color: #206ee2;
  text-decoration: underline;
  cursor: pointer;
}
</style>