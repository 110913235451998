<template>
  <a-modal width="50%" :visible="visible" title="视频预览" :footer="null" @cancel="handleCancel">
    <div class="modelVideo">
      <video id="video" class="video" autoplay="autoplay" controls="controls" :src="href" style="width= 100%; height=100%; object-fit: fill"></video>
    </div>
  </a-modal>
</template>

<script>
import { nextTick, watch } from 'vue'
export default {
  props: {
    visible: {},
    href: {}
  },
  setup (props, ctx) {
    const handleCancel = () => {
      ctx.emit('update:visible', false)
    }

    watch(() => props.visible, (newVal) => {
      if (newVal) {
        nextTick(() => {
          const $videos = document.getElementById('video')
          $videos.play()
        })
      } else {
        const $videos = document.getElementById('video')
        $videos.pause()
      }
    })
    return {
      handleCancel
    }
  }
}
</script>

<style lang="scss" scoped>
.modelVideo{
  width:100%;
  height: 100%;
  .video{
    width:100%;
    height:100%;
  }
}
</style>
